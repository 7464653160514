<template>
  <div class="bgcard">
    <div class="searchlist">
      <div class="select_b" style="margin-right: 0.2rem">
        <span style="color: #bbbbbb">{{ $t("lang.Equipmentmodel") }}：</span>
        <el-select
          style="height: 0.4rem; width: 1.5rem"
          v-model="sources"
          :placeholder="$t('lang.Pleaseselectthesourcedevice')"
          popper-class="select_a"
          @change="stauts"
        >
          <el-option
            :label="$t('lang.whole')"
            v-if="models.length > 0"
            :value="''"
          >
          </el-option>
          <el-option
            v-for="item in models"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="searchtime">
        <span style="color: #bbbbbb">{{ $t("lang.time") }}：</span>
        <el-date-picker
          v-model="time"
          type="daterange"
          :range-separator="$t('lang.to')"
          :start-placeholder="$t('lang.Startdate')"
          :end-placeholder="$t('lang.Enddate')"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          @change="picker"
        >
        </el-date-picker>
      </div>
      <div class="query">
        <div class="inputname">
          <input
            v-model="input"
            :placeholder="$t('lang.Pleaseenterthefilenameoruser')"
          />
        </div>
        <div class="searchbtn">
          <button @click="sear">
            <p>{{ $t("lang.search") }}</p>
          </button>
        </div>
      </div>
    </div>
    <div class="serverfroms">
      <el-table :data="tableData.list" style="width: 100%">
        <template slot="empty">
          <div class="noCart">{{ $t("lang.tableNull") }}</div>
        </template>
        <el-table-column
          prop="showName"
          width="350"
          :label="$t('lang.fileNames')"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          width="250"
          :label="$t('lang.creationTime')"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="deviceName"
          :label="$t('lang.sourceEquipment')"
          align="center"
					width="250"
        >
        </el-table-column>
        <el-table-column prop="owner" :label="$t('lang.User')" align="center" width="300">
        </el-table-column>
        <el-table-column
          prop="length"
          :label="$t('lang.programDuration')"
          align="center"
					width="200"
        >
        </el-table-column>
        <el-table-column
          :label="$t('lang.filesizes')"
          align="center"
					width="200"
        >
          <template  #default="scope">
            <span>{{ scope.row.size }}MB</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('lang.operation')"
          align="center"
          width="300"
        >
          <template #default="scope">
            <div class="bottom_tabes">
              <el-tooltip effect="dark" :content='$t("lang.copyAddress")' placement="left">
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(1, scope.row.id, scope.row.flag1)"
                  @mouseleave="leaveOne(1, scope.row.id, scope.row.flag1)"
                  @click="clone(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag1 === 0"
                    src="@/assets/imgs/bottom/fzurl.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag1 === 1"
                    src="@/assets/imgs/bottom/fzurl2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content='$t("lang.download")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_compile"
                  @mouseenter="enterOne(2, scope.row.id, scope.row.flag2)"
                  @mouseleave="leaveOne(2, scope.row.id, scope.row.flag2)"
                  @click="download(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag2 === 0"
                    src="@/assets/imgs/bottom/xiazai.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag2 === 1"
                    src="@/assets/imgs/bottom/xiazai2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content='$t("lang.edit")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_detail"
                  @mouseenter="enterOne(3, scope.row.id, scope.row.flag3)"
                  @mouseleave="leaveOne(3, scope.row.id, scope.row.flag3)"
                  @click="edit(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag3 === 0"
                    src="@/assets/imgs/bottom/bianji.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag3 === 1"
                    src="@/assets/imgs/bottom/bianji2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
              <div class="btn_dise">|</div>
              <el-tooltip
                effect="dark"
                :content='$t("lang.deletes")'
                placement="left"
              >
                <div
                  class="bottom_imgs bottom_delete"
                  @mouseenter="enterOne(4, scope.row.id, scope.row.flag4)"
                  @mouseleave="leaveOne(4, scope.row.id, scope.row.flag4)"
                  @click="deletes(scope.row)"
                >
                  <img
                    class="imgOne"
                    v-if="scope.row.flag4 === 0"
                    src="@/assets/imgs/bottom/shanchu.png"
                    alt=""
                  />
                  <img
                    class="imgTwo"
                    v-if="scope.row.flag4 === 1"
                    src="@/assets/imgs/bottom/shanchu2.png"
                    alt=""
                  />
                </div>
              </el-tooltip>
            </div>
            <!-- <el-button class="buttons" size="mini" @click="clone(scope.row)">{{
              $t("lang.copyAddress")
            }}</el-button>
            <el-button
              class="buttons"
              size="mini"
              @click="download(scope.row)"
              >{{ $t("lang.download") }}</el-button
            >
            <el-button class="buttons" size="mini" @click="edit(scope.row)">{{
              $t("lang.edit")
            }}</el-button>
            <el-button
              class="buttons"
              size="mini"
              @click="deletes(scope.row)"
              >{{ $t("lang.deletes") }}</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; align-items: center" v-if="tableData.list">
        <div class="allNum">{{ $t("lang.total") }}: {{ tableData.total }}</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="tableData.total"
          @current-change="handlePageCurrentChange"
          :prev-text="$t('lang.previouspage')"
          :next-text="$t('lang.nextpage')"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 编辑、删除模态框 -->
    <el-dialog
      :title="titles"
      :visible.sync="showSees"
      :show-close="false"
      width="25%"
      top="2.5rem"
      custom-class="seteqcs_a"
    >
      <div v-if="sing == 1">
        <div class="binding">
          <label>{{ $t("lang.fileNames") }}：</label>
          <el-input
            v-model="bindings.name"
            :placeholder="$t('lang.pleaseEnterAFileName')"
            @input="inputs"
          ></el-input>
        </div>
      </div>
      <div class="delete" v-if="sing == 2">
        <span>{{ $t("lang.Areyousureyouwanttodelete") }}？</span>
      </div>
      <div>
        <span class="dialog-footer">
          <button class="button_a" @click="showSees = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button class="button_a" @click="determine">
            <p>{{ $t("lang.determine") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as media from "@/api/media.js";
import * as pment from "@/api/equipment.js";
export default {
  created() {},
  data() {
    return {
      seenOnetext: this.$t("lang.binding"),
      input: "",
      time: "",
      sources: "",
      parsm: {
        page: 1,
        size: 10,
        createTime: "",
        model: "",
        nameOrEmail: "",
        stopTime: "",
        email: "",
      },
      tableData: [],
      models: "", //来源设备
      titles: "",
      showSees: false,
      sing: "",
      bindings: {},
    };
  },
  mounted() {
    this.getList();
    this.GetModel();
  },
  methods: {
    enterOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag1 = 1;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag2 = 1;
          }
        }
        if (index === 3) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag3 = 1;
          }
        }
        if (index === 4) {
          if (ids == this.tableData.list[i].id && flag === 0) {
            this.tableData.list[i].flag4 = 1;
          }
        }
      }
    },
    leaveOne(index, ids, flag) {
      for (let i in this.tableData.list) {
        if (index === 1) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag1 = 0;
          }
        }
        if (index === 2) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag2 = 0;
          }
        }
        if (index === 3) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag3 = 0;
          }
        }
        if (index === 4) {
          if (ids == this.tableData.list[i].id && flag === 1) {
            this.tableData.list[i].flag4 = 0;
          }
        }
      }
    },
    // 转化时间
    timeFuns(value) {
      let theTime = value; //秒
      let middle = 0; //分
      let hour = 0; //小时
      if (theTime > 59) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
      }
      if (middle > 59) {
        hour = parseInt(middle / 60);
        middle = parseInt(middle % 60);
      }
      theTime < 10 ? (theTime = "0" + theTime) : (theTime = theTime);
      middle < 10 ? (middle = "0" + middle) : (middle = middle);
      hour < 10 ? (hour = "0" + hour) : (hour = hour);
      return hour + ":" + middle + ":" + theTime;
    },
    //获取媒体列表
    async getList() {
      let res = await media.list(this.parsm);
      if (res.data.list) { 
        res.data.list.forEach((item) => {
          item.flag1 = 0;
          item.flag2 = 0;
          item.flag3 = 0;
          item.flag4 = 0;
          item.length = this.timeFuns(item.length);
        });
        this.tableData = res.data;
      }else{
        this.tableData = [];
      }
    },
    //获取设备型号
    async GetModel() {
      let res = await pment.model();
      if (res.data) {
        this.models = res.data;
      }
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.parsm.page = page;
      this.getList();
    },
    //文件名、邮箱筛选
    sear() {
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.nameOrEmail = this.input;
      this.getList();
    },
    //来源设备筛选
    stauts(e) {
      console.log(e);
      this.parsm.page = 1;
      this.parsm.size = 10;
      this.parsm.model = e;
      this.getList();
    },
    //日期筛选
    picker(e) {
      console.log(e);
      this.parsm.page = 1;
      this.parsm.size = 10;
      if (e) {
        this.parsm.createTime = e[0];
        this.parsm.stopTime = e[1];
      } else {
        this.parsm.createTime = "";
        this.parsm.stopTime = "";
      }
      this.getList();
    },
    //复制地址
    clone(row) {
		let addr=window.location.origin+row.downloadURL
      this.$copyText(addr).then(
        (res) => {
          this.$message.success(this.$t("lang.Copysucceeded"));
        },
        (error) => {
          this.$message.success(this.$t("lang.copyfailed"));
        }
      );
    },
    //下载
    download(row) {
      window.location.href = row.downloadURL;
    },
    //编辑
    edit(row) {
      this.showSees = true;
      this.bindings = {};
      this.sing = 1;
      this.bindings.id = row.id;
      this.bindings.name = row.showName;
      this.titles = this.$t("lang.Editfile");
    },
    //刷新输入框内容
    inputs() {
      this.$forceUpdate();
    },
    //删除
    deletes(row) {
      this.bindings = {};
      this.showSees = true;
      this.sing = 2;
      this.bindings.id = row.id;
      this.titles = this.$t("lang.Deletefile");
    },
    //编辑、删除确定
    determine() {
      if (this.sing == 1) {
        if (this.bindings.name == "") {
          this.$message.error(this.$t("lang.pleaseEnterAFileName"));
          return false;
        }
        media.edit(this.bindings).then((res) => {
          this.$message.success(this.$t("lang.Operations"));
          this.showSees = false;
          this.getList();
        });
      } else {
        media.deletes([this.bindings.id]).then((res) => {
          this.$message.success(this.$t("lang.Operations"));
          this.showSees = false;
          this.getList();
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;
}
.buttons {
  // padding: 0px;
  // width: 82px;
  // height: 42px;
  outline-style: none;
  border: none;
  border-radius: 0.04rem;
  font-size: 0.16rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.buttons:nth-child(1) {
  background-color: rgb(36, 180, 222);
}
.buttons:nth-child(2) {
  background-color: rgb(16, 97, 197);
}
.buttons:nth-child(3) {
  background-color: rgb(48, 123, 55);
}
.buttons:nth-child(4) {
  background-color: rgb(67, 67, 67);
}
.binding {
  margin-bottom: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    width: 0.8rem;
  }
  .el-input,
  .el-select {
    width: 2rem;
  }
}
.delete {
  height: 0.5rem;
  text-align: center;
  font-size: 0.16rem;
  color: #333333;
}
.button_a {
  border: none;
  outline-style: none;
  width: 0.92rem;
  height: 0.46rem;
  background: #ededed;
  border: 0.01rem solid #438749;
  border-radius: 0.1rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #438749;
    line-height: 0.35rem;
    text-align: center;
  }
}
.button_a:nth-child(2) {
  width: 0.92rem;
  height: 0.46rem;
  background: #438749;
  border-radius: 0.1rem;
  margin-left: 0.2rem;

  p {
    font-size: 0.18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.35rem;
  }
}
</style>
